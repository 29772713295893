@import (reference) 'vars';

:root {
    --table-container-bg: @console-background;
}

uf-table-container {
    --app-bar-height: @app-bar-height;
    --app-bar-font-size: @font-size;
}

.uf-table th.table-header {
    padding: 0;
}