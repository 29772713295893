// ------------------------------------------
// Colours

@brand: var(--brand, #262626);
@brand-accent: var(--brand-accent, #177e89);

@primary-action: var(--primary-action, #177E89);
@primary-action-disabled: var(--primary-action-disabled, #8BBEC4);
@primary-action-hover: var(--primary-action-hover, #39919A);
@primary-action-pressed: var(--primary-action-pressed, #115E66);

@secondary-action: var(--secondary-action, white);
@secondary-action-disabled: var(--secondary-action-disabled, white);
@secondary-action-hover: var(--secondary-action-hover, #EEF6F6);
@secondary-action-pressed: var(--secondary-action-pressed, #C4D8DC);

@tertiary-action: transparent;
@tertiary-action-disabled: transparent;
@tertiary-action-hover: fade(black, 7.5%);
@tertiary-action-pressed: fade(black, 10%);

@gray-5: #f4f4f4;
@gray-10: #e7e7e7;
@gray-20: #d0d0d0;
@gray-40: #8f8f8f;
@gray-60: #737373;
@gray-80: #454545;
@gray-100: #161616;

@info: var(--info, #325786);
@success: var(--success, #017E3C);
@warning: var(--warning, #FBA600);
@error: var(--error, #AF1211);

// ------------------------------------------
// Spacing

@gutter: 1rem; // Default distance between 2 columns

@space-1: 0.125rem;
@space-2: 0.25rem;
@space-3: 0.5rem;
@space-4: 0.75rem;
@space-5: 1rem;
@space-6: 1.5rem;
@space-7: 2rem;
@space-8: 2.5rem;
@space-9: 3rem;
@space-10: 4rem;
@space-11: 6rem;
@space-12: 10rem;


// ------------------------------------------
// Text

@font-family: 'Open Sans', sans-serif;
@font-family-secondary: 'Nunito Sans', sans-serif;

@font-size-xxs: 0.60rem;
@font-size-xs: 0.75rem;
@font-size-sm: 0.875rem;
@font-size: 1rem;
@font-size-lg: 1.25rem;
@font-size-caption: 0.75rem;

@line-height-xxs: 0.875rem;
@line-height-xs: 1rem;
@line-height-sm: 1.25rem;
@line-height: 1.5rem;
@line-height-lg: 1.75rem;
@line-height-caption: 1.125rem;

@text-color: @gray-80;
@text-color-secondary: @gray-60;
@text-color-label: @brand-accent;

// todo: fix this!
@font-weight-bold: 700;


// ------------------------------------------
// Icon

@icon-size-xs: @line-height-xs;
@icon-size-sm: @line-height-sm;
@icon-size: @line-height;
@icon-size-lg: 1.875rem;
@icon-size-xl: 3.5rem;

// In sync with font default and secondary
@icon-color: @text-color;
@icon-color-secondary: @text-color-secondary;


// ------------------------------------------
// Link

@link: var(--link, #357bb7);
@link-hover: var(--link-hover, #62b0ff);
@link-pressed: var(--link-pressed, #144876);

// ------------------------------------------
// Foundation

@border-radius: var(--border-radius, 0.25rem);
@border-radius-button: var(--button-border-radius, 0.25rem);

@box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.2);
@box-shadow-md: 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 5px 6px rgba(0, 0, 0, 0.2);
@box-shadow-lg: 0px 6px 28px rgba(0, 0, 0, 0.12), 0px 15px 22px rgba(0, 0, 0, 0.14), 0px 8px 9px rgba(0, 0, 0, 0.2);
@box-shadow-xl: 0px 8px 40px rgba(0, 0, 0, 0.12), 0px 21px 33px rgba(0, 0, 0, 0.14), 0px 10px 13px rgba(0, 0, 0, 0.2);

@border: 1px solid @gray-10;

// ------------------------------------------
// Screen widths
// in pixels because viewport is in pixels

@screen-width-xs: 394px; // 394px
@screen-width-sm: 576px; // 576px
@screen-width-md: 768px; // 768px
@screen-width-lg: 992px; // 992px
@screen-width-xl: 1200px; // 1200px
@screen-width-xxl: 1584px; // 1584px  Max screen size

// Mobile Screen width
// Base con ipad portrait size

@screen-width-mobile: 768px;


// ------------------------------------------
// column widths

// Helper for setting widths of containers relative to the grid width
// Possibly can be extended to include large: 64px and max: 96px; if required
// https://www.carbondesignsystem.com/guidelines/2x-grid/basics

@column-width: 5rem; // 80px


// ------------------------------------------
// APP bar

@app-bar-height-xs: 2.25rem;
@app-bar-height-sm: 3rem;
@app-bar-height: 3.5rem;

// ------------------------------------------
// Table
@table-outline-color: #1616160c;

// ------------------------------------------
// Smart forms

@input-width: 23.5rem;

@input-label: var(--input-label, #177E89);

@group: var(--group, #336d79);
@group--second-level: var(--group-second-level, #3b7e8c);
@group--third-level: var(--group-third-level, #71a0aa);
@group--fourth-level: var(--group-fourth-level, #dbe7ea);
@group--child: var(--group-child, #dcdcdc);



@input-border-radius: var(--input-border-radius, 0em);
@input-background: var(--input-background, transparent);

@input-border--top: var(--input-border-top, none);
@input-border--right: var(--input-border-right, none);
@input-border--left: var(--input-border-left, none);
@input-border--bottom: 1px solid @gray-20;
@input-border--bottom--warning: 1px solid @warning;
@input-border--bottom--error: 1px solid @error;

@input-icon-padding-right: var(--input-icon-margin-right, 0);
/** set to 8px */
@input-icon-colour: var(--input-icon-colour, #737373);

@label-padding--bottom: var(--label-padding-bottom, 0);
/** set to 0.25em for other style */


/**
    Deprecated
    DONT USE!!!!!
*/

@mobile-screen: 48rem;
@desktop-screen: 769px;
@desktop-screen--large: 1024px;


// ------------------------------------------
// layout

@fixed-grid-width: 63rem;
@fixed-grid-width--small: 47rem;